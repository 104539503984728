<template>
<main class="contents_main">
  <div class="contents">
    <section id="step" class="step">
    <h2 class="guide_t0 guild u-pt40">サタデーオークションに参加される方へ</h2>
    <div class="row guild-row u-mb40">
        <p class="col-md-3 col-12"><a @click="gotoAnchor('t_link1')">
        参加(入札)される方へのご案内
        </a></p>
        <p class="col-md-3 col-12"><a @click="gotoAnchor('t_link2')">
        出品者へのご案内
        </a></p>
        <p class="col-md-3 col-12"><a @click="gotoAnchor('t_link3')">
        注意事項
        </a></p>
        <p class="col-md-3 col-12"><a @click="gotoAnchor('t_link4')">
        業務規程
        </a></p>
    </div>
    <a class="anchor" id="t_link1"></a>
    <h2 class="guide_t guild">参加(入札)される方へのご案内</h2>
    <h3 class="u-mb30">
        サタデーオークションへの参加(入札)を希望される方はこちらをご覧ください。
    </h3>
    <ul class="step u-mb30 li-mb15">
        <li class="guide_step">
        <h3><em>STEP1</em>会員登録</h3>

        <div class="step_inner">
            オークションへの参加(入札)には会員登録が必要です。こちらから会員登録をしてください。
            <div class="register">
            <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)" class="btn_register">会員登録をする(無料)</a>
            </div>
            登録は無料です。主催者にて所定の審査を行い、利用者登録が完了した方にはオークション事務局からメールでお知らせします。<br>
            <br>
            <span class="cred u-em9">※会員登録にドコモ、au、ソフトバンク等携帯電話会社のメールアドレスを使われると、主催者からのメールが迷惑メールフィルターに引っ掛かり正しく届かない可能性があります。<br />
            登録にはなるべくgmailやヤフーメール等のアドレスをお使いください。</span>
        </div>
        </li>
        <li class="guide_step">
        <h3><em>STEP2</em>オークション開催</h3>

        <div class="step_inner">
            オークションは毎週、以下のスケジュールで開催します。<br />
            <br />
            <table>
            <tr>
                <td>
                水曜日
                </td>
                <td>
                夜
                </td>
                <td>
                当週のオークションの上場馬をwebサイトで公開
                </td>
            </tr>
            <tr>
                <td>
                金曜日
                </td>
                <td>
                夜
                </td>
                <td>
                上場馬の詳細情報をwebサイトで公開
                </td>
            </tr>
            <tr>
                <td style="border-bottom:0;">
                土曜日
                </td>
                <td>
                12:00
                </td>
                <td>
                オークション開始
                </td>
            </tr>
            <tr>
                <td>

                </td>
                <td>
                夜
                </td>
                <td>
                オークション終了<br />
                    (終了時刻は21:00以降で、馬によって異なります。またオークションは自動延長を行います。終了予定時刻の3分前以降に入札があり、その時点での現在価格が変わった場合、終了時刻を自動的に延長するものです。終了時刻は入札が行われた時点より3分間延長され、延長中に再度入札があり現在価格が再び変わった場合はその時点からさらに3分間延長されます。また、その時点でトップの入札者がより高い価格で入札を行った場合も終了時刻が3分間延長されます。)
                </td>
            </tr>

            </table>
            <br />
            会員登録をしたメールアドレスとパスワードでログインし、出品馬の中から購買を希望される馬に入札をしてください。サタデーオークションでは入札ピッチを設定しており、入札しようとしている価格帯のピッチに従った金額でのみ入札が可能です。入札ピッチは全ての上場馬に共通し、以下のとおりとなります。<br />
            <br />
            <ul class="subline">
            <li>
                200万円以下の場合　10,000円
            </li>
            <li>
                200万円を超え、500万円以下の場合　20,000円 (202万円, 204万円, 206万円…498万円, 500万円)
            </li>
            <li>
                500万円を超え、1000万円以下の場合　50,000円 (505万円, 510万円, 515万円…995万円, 1000万円)
            </li>
            <li>
                1000万円を超える場合　100,000円 (1010万円, 1020万円, 1030万円…)
            </li>
            </ul>
            <br />
            <span class="cred u-em9">※入札ピッチから外れる金額(例: 201万円, 203万円, 502万円, 1005万円など)での入札はできません。</span>

        </div>
        </li>
        <li class="guide_step">
        <h3><em>STEP3</em>落札者の決定と代金のお支払い</h3>

        <div class="step_inner">
            オークションの終了時に最高額での入札をしていた利用者がその馬の落札者となります。落札者にはオークション終了後に落札金額と入金先口座を記載したメールをお送りし、またオークション終了日の翌日にご登録のメールアドレスにご請求書をお送りします。ご確認のうえ、落札金額をお振込みください。落札金額が入金された時点でその馬の所有権は落札者に移ります。<br />
            <br />
            入金の確認後、主催者から落札者に馬匹売買契約書をメールでお送りします。署名のうえ速やかに主催者にご返送ください。
        </div>
        </li>
        <li class="guide_step">
        <h3><em>STEP4</em>落札馬の引き渡し</h3>

        <div class="step_inner">
            落札した馬はその在厩場所にて引き渡しとなります。オークション終了後5日以内に落札した馬を移動させてください。<br />
            オークション終了後2日め(月曜日)以降は在厩場所の預託料がかかります。<br />
            移動の馬運車の手配は原則として落札者が行うものとしますが、主催者に手配を依頼されたい方は以下までご連絡ください。<br />
            <br />
            <ul>
            <li>
                サタデーオークション事務局
            </li>
            <li>
                電話: 077-516-4398　<br class="is-sp">メール: sat-auction@eqmo.co.jp
            </li>
            </ul>
        </div>
        </li>
    </ul>
    <a class="anchor" id="t_link2"></a>
    <h2 class="guide_t guild">出品者へのご案内</h2>
    <h3 class="u-mb30">
        出品を希望される方はこちらをご覧ください。
    </h3>
    <ul class="step u-mb30 li-mb15">
        <li class="guide_step">
        <h3><em>STEP1</em>出品のお申し込み</h3>

        <div class="step_inner">
            オークション出品を希望される方は上場馬募集要項をお読み頂いた上で、毎週火曜日17時までに出品馬の情報を販売申込書に記載し事務局宛てにメールもしくはFAXでお送りください。<br />
            2頭以上の出品馬がある場合は、1頭ずつ申込書のご記入をお願いします。<br />
            サタデーオークションに初めて出品される方で、適格請求書発行事業者の登録を受けられている方(インボイス登録番号をお持ちの方)は、販売申込書2ページめのインボイス登録番号記入欄に登録番号を必ずご記入ください。
            <br /><br />
            <ul>
            <li>
                上場馬募集要項 (<a @click="downloadFile(horseRequirementPdf)">PDF</a>)
            </li>
            <li>
                現役競走馬販売申込書 (<a @click="downloadFile(activeRacingHorseExcel)">Excel</a> / <a @click="downloadFile(activeRacingHorsePdf)">PDF</a>)
            </li>
            <li>
                繁殖牝馬販売申込書(<a @click="downloadFile(breedingMareExcel)">Excel</a> / <a @click="downloadFile(breedingMarePdf)">PDF</a>)
            </li>
            </ul>
            <br />
            <span class="cred u-em9">
            ※ webサイトに掲載する紹介文・写真・動画などは、水曜日17時までに事務局にお送りください。<br />
            ※ 前週の土・日曜日に出走した馬の出品が可能です。直前に出走した馬を出品される場合は、必ずレース後の馬の体調・傷病等を確認し事務局にお知らせください。<br />
            ※ 出品をキャンセルされたい場合は、オークション開催日前日の金曜日17時までにご連絡をお願いします。
            </span>
        </div>
        </li>
        <li class="guide_step">
        <h3><em>STEP2</em>オークション開催</h3>

        <div class="step_inner">
            出品馬の落札結果は、土曜日(オークション開催日)以降にwebサイトでご確認ください。
        </div>
        </li>
        <li class="guide_step">
        <h3><em>STEP3</em>代金のお支払い</h3>

        <div class="step_inner">
            出品馬が落札された場合は、オークション終了後7営業日以内に落札価格から所定の手数料を差し引いた金額を出品者の金融機関口座に送金します。また、落札価格と手数料の明細を記した精算通知書をお送りします。<br />
            <br />
            出品馬が落札されなかった場合(主取り)は、出品手数料のご請求書をお送りします。<br />
            なお、主取り馬は翌週以降のオークションに再度出品することが可能です。再出品される場合は一回に限り出品手数料を無料とします。<br />
            <br />
            <ul>
            <li>
                ご質問やご不明な点は主催者までご連絡ください。
            </li>
            <li>
                サタデーオークション事務局
            </li>
            <li>
                電話: 077-516-4398　<br class="is-sp">メール: sat-auction@eqmo.co.jp
            </li>
            </ul>
        </div>
        </li>
    </ul>
    <a class="anchor" id="t_link3"></a>
    <h2 class="guide_t guild">注意事項</h2>
    <h3 class="u-mb30 guideline">
        サタデーオークションの開催について利用者の皆様に遵守頂くべき注意事項です。<br />
        出品者・入札者を問わずオークションを利用される方は必ずお読み頂き、ご理解頂いた上でオークションへのご参加をお願いいたします。
    </h3>
    <ul class="notes">
        <li>
        <h4>1. 公表事項</h4>
        <p>
            出品者は出品馬について以下の事項を確認し出品申込書に正確に記載してください。<br />
            以下について出品申込書の記載事項が事実と異なっていた場合、落札者は売買契約成立日の翌日から5日以内に書面にて売買契約の解除を申し出ることができます。<br />
            なお、下記の(4)、(5)もしくは(6)を理由とする場合、獣医師による診断書を添付する必要があります。
        </p>
        <ul>
            <li>
            (1)	日本中央競馬会の見舞金の受給歴
            </li>
            <li>
            (2)	日本中央競馬会の発走試験の履歴
            </li>
            <li>
            (3)	日本中央競馬会の裁決委員による調教審査の履歴
            </li>
            <li>
            (4) 運動誘発性の肺出血に起因する鼻出血の発症歴
            </li>
            <li>
            (5)	悪癖 (さく癖・旋回癖・熊癖・身喰い)
            </li>
            <li>
            (6)	傷病歴及び手術歴 (目の異常、喘鳴症(ノド鳴り)、骨折(剥離骨折を含む)歴、骨折(剥離骨折を含む)に起因する外科手術歴、屈腱炎、繋靭帯炎、蹄の不安、去勢、開腹手術歴、脊椎管狭窄による下半身麻痺を含むが、これに限らない)
            </li>
        </ul>
        </li>
        <li>
        <h4>2. 公表事項の訂正と現状報告の義務</h4>
        <p>
            出品者は、出品申込書に記載した公表事項が事実と異なっていることを認識した場合、速やかに主催者に報告する義務があります。<br />
            また、出品の申し込み後に出品馬の状態に変化(傷病・熱発・歩様の乱れ等)が発生した場合も速やかにその旨を主催者に報告しなければなりません。
        </p>
        </li>
        <li>
        <h4>3. 入札者の注意義務</h4>
        <p>
            入札者は出品馬に関する情報を全て理解した上で入札を行ってください。<br />
            1に記載の公表事項について、出品者より正しい情報が提供された上で上場されている馬についてはそれを理由とした売買契約の取り消しは認められません。<br />
            また、主催者は出品者より随時提供される情報を基に出品馬の情報の更新を行うことがあります。必ず最新の情報を確認した上で入札を行ってください。
        </p>
        </li>
        <li>
        <h4>4. 入札間違いの防止</h4>
        <p>
            入札の間違いはいかなる理由でも認められません。<br />
            入札時には必ず入札をする馬と入札金額に間違いがないことを確認した上で入札を行ってください。<br />
            入札確認画面では入札金額は税別価格にて表示し、税込金額を併記しています。
        </p>
        </li>
        <li>
        <h4>5. 馬の引き渡し</h4>
        <p>
            落札者は、落札した馬を引き取る前に自らの費用負担において獣医師による検査をすることが可能です。<br />
            公表事項に基づく売買契約の解除の申し出には獣医師発行の診断書等の書面が必要になりますので、獣医師検査をされることをお勧めします。<br />
            <br />
            落札金額が入金された時点でその馬の所有権は落札者に移転します。<br />
            移動の馬運車の手配は原則として落札者が行うものとしますが、主催者に手配を依頼されたい方は以下までご連絡ください。
        </p>
        </li>
        <li>
        <h4>6. 売買契約の解除</h4>
        <p>
            落札者は、落札した馬について以下のうちいずれかの事項がサタデーオークションのwebサイトでの公表事項と異なることを確認した場合、売買契約成立日の翌日から5日以内に書面にて売買契約の解除を申し出ることができます。<br />
            なお、下記の(4)、(5)もしくは(6)を理由とする場合、獣医師による診断書を添付する必要があります。
        </p>
        <ul>
            <li>
            (1)	日本中央競馬会の見舞金の受給歴
            </li>
            <li>
            (2)	日本中央競馬会の発走試験の履歴
            </li>
            <li>
            (3)	日本中央競馬会の裁決委員による調教審査の履歴
            </li>
            <li>
            (4) 運動誘発性の肺出血に起因する鼻出血の発症歴
            </li>
            <li>
            (5)	悪癖 (さく癖・旋回癖・熊癖・身喰い)
            </li>
            <li>
            (6)	傷病歴及び手術歴 (目の異常、喘鳴症(ノド鳴り)、骨折(剥離骨折を含む)歴、骨折(剥離骨折を含む)に起因する外科手術歴、屈腱炎、繋靭帯炎、蹄の不安、去勢、開腹手術歴、脊椎管狭窄による下半身麻痺を含むが、これに限らない)
            </li>
        </ul>
        <p>
            売買契約が成立した日の翌日から5日以内に申し出が行われなかった場合、いかなる理由においても売買契約を解除することはできません。
        </p>
        </li>
    </ul>
    <a class="anchor" id="t_link4"></a>
    <h2 class="guide_t guild">業務規程</h2>
    <h3 class="u-mb30 guideline">
        サタデーオークションの業務規程と上場馬募集要項です。<br />
        出品者・入札者を問わずオークションを利用される方は必ずお読み頂き、ご理解頂いた上でオークションへのご参加をお願いいたします。<br />
    </h3>

    <div class="register">
        <a @click="downloadFile(businessProcessPdf)" class="btn_kitei">
        サタデーオークション業務規程
        <span class="material-symbols-outlined">
            picture_as_pdf
        </span>
        </a>
    </div>
    <div class="register">
        <a @click="downloadFile(horseRequirementPdf)" class="btn_kitei">
        上場馬募集要項
        <span class="material-symbols-outlined">
            picture_as_pdf
        </span>
        </a>
    </div>

    </section>

  </div>
</main>
</template>
<script>
import DownloadFile from '@/common/downloadFile.js';
export default {
  mixins  : [DownloadFile],
  methods : {
    gotoAnchor(idName) {
      const element = document.getElementById(idName)
      if (element) {
        element.scrollIntoView()
      }
    }
  }
}
</script>

<style scoped>
    .inquiry >>> p {
        margin-bottom: initial !important;
    }
    .row {
      margin-top: inherit !important;
      margin-bottom: inherit !important;
    }
    .step ul.step {
      padding-left: inherit !important;
    }
    .register a, .step_inner >>> a, .guild-row a {
        cursor: pointer !important;
        text-decoration: none !important;
    }
    .btn_yoko {
        border-color:#fff707 !important;
        box-shadow:1px 2px 0 2px #fff707 !important;
    }
    a.anchor {
        display: block;
        position: relative;
        top: -60px;
        visibility: hidden;
    }
    @media screen and (max-width: 1024px) {
        a.anchor {
            top: -20px;
        }
    }
    .guild-row a {
        color: #005BAC;
    }
</style>
<style scoped src="@/assets/css/contents03.css"></style>
